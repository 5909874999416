@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
	box-sizing: border-box;
}

html {
	color-scheme: dark;
}

body {
	background: #f7f7f7;
	color: black;

	padding: 0;
	margin: 0;
}

/* Assuming .filter is the class for the container and .MuiButton-root is the class for the button */
.filter button {
  display: flex;
  justify-content: end;
	text-indent: -9999px !important;
	position: relative;
  min-width: 104px;
  margin: 0 auto;
  text-align: center;
}

.filter button::before {
	content: 'Пошук';
	display: inline-block;
	position: absolute;
	text-indent: -130px;

}
