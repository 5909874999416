.itemChip {
	background-color: #703eff !important;
	color: #fff !important;
	border-radius: 20px !important;
	padding: 3px 8px !important;
	height: 22px !important;
	display: flex;
	justify-content: center;
	align-items: center;

	span {
		padding: 0;
	}
}
