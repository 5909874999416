.elementText {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
}

.controlsContainer {
    position: absolute;
    right: 20px;
    bottom: 30px;
    z-index: 1000000;
}

.currentPosition {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #81A5EC;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    cursor: pointer;
}

.zoomInButton {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    cursor: pointer;
}

.zoomOutButton {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    cursor: pointer;
}